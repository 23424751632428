<template>
    <v-app :style="{ background: $root.getTheme().background }">
        <navbar />
        <router-view class="router-view" />
        <loading-screen :loading="httpState.loading" />
    </v-app>
</template>

<script>
import event from '../utils/event';
import userStore from '../stores/user-store';
import http from '../services/http';

export default {
    components: {
        navbar: require('./AppInhouseNavbar.vue').default
    },
    data () {
        return {
            dialog: false,
            userState: userStore.state,
            logoutProcessing: false,
            httpState: http.state,
        };
    },
    mounted () {
        event
            .on('unauthorized', async () => {
                if (this.logoutProcessing) return;
                this.logoutProcessing = true;

                await this.showError('ログイン状態が続いたため、接続が切断されました。再度、ログインしてください。', '認証エラー');

                localStorage.setItem('ams-logined-to', this.$route.fullPath);
                location.href = '/login';
            });
    },
    beforeDestroy() {
        event.off('unauthorized');
    },
    methods: {
        getTheme () {
            return this.theme;
        },
    },
};
</script>
