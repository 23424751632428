<template>
    <div>
        <!-- メインメニュー -->
        <v-navigation-drawer v-model="navBar" fixed clipped app>
            <v-list class="pt-0" dense>
                <v-list-group sub-group value="true">
                    <template v-slot:activator>
                        <v-list-item-title>アルバム制作</v-list-item-title>
                    </template>
                    <v-list-item to="/inhouse/albums" @click="clickNavBarMenu">
                        <v-list-item-icon>
                            <v-icon>photo_library</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>アルバム一覧</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="items.length">
                        <v-treeview :style="{ maxWidth: '216px' }" dense hoverable activatable
                            :load-children="fetchShootings" :items="items" @update:active="treeviewNodeClicked" />
                    </v-list-item>
                </v-list-group>

                <v-list-group sub-group value="true">
                    <template v-slot:activator>
                        <v-list-item-title>管理</v-list-item-title>
                    </template>
                    <v-list-item to="/inhouse/customers" @click="clickNavBarMenu">
                        <v-list-item-icon>
                            <v-icon>domain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>顧客管理</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/inhouse/settings" @click="clickNavBarMenu">
                        <v-list-item-icon>
                            <v-icon>settings</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>システム設定</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openOnlineManual">
                        <v-list-item-icon>
                            <v-icon>mdi-file-document-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>マニュアルを開く</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="toggleTheme">
                        <v-list-item-icon>
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            テーマ切替
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item @click="logout" style="padding-left: 24px;">
                    <v-list-item-icon style="margin: 8px 16px 8px 0;">
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        ログアウト
                    </v-list-item-title>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>
        <!-- メインヘッダ -->
        <v-app-bar clipped-left fixed app color="secondary" elevate-on-scroll elevation="25">
            <v-app-bar-nav-icon @click.stop="navBar = !navBar">
                <v-icon>menu</v-icon>
            </v-app-bar-nav-icon>
            <v-container fluid class="ma-0 pa-0">
                <v-row justify="space-between">
                    <v-col align="center">
                        <v-container>
                            <v-row>
                                <v-breadcrumbs :items="$root.$data.breadcrumbs">
                                    <template v-slot:divider>
                                        <v-icon>keyboard_arrow_right</v-icon>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-breadcrumbs-item v-if="item.text" class="no-underline-breadcrumbs"
                                            :href="item.href" :disabled="item.disabled">
                                            {{ item.text }}
                                        </v-breadcrumbs-item>
                                        <v-breadcrumbs-item v-else class="no-underline-breadcrumbs" :href="item.href"
                                            :disabled="item.disabled">
                                            <div v-for="(text, index) in item.texts" :key="index" class="d-inline-flexs">
                                                <icon-sex-ratio v-if="text.type == 'iconSexRatio'" v-bind="text.value" />
                                                <span v-else class="mr-1">
                                                    {{ text.value }}
                                                </span>
                                            </div>
                                        </v-breadcrumbs-item>
                                    </template>
                                </v-breadcrumbs>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col>
                        <v-container class="pa-4">
                            <v-row justify="end">
                                <v-toolbar-items v-for="action in $root.$data.headerActions" :key="action.name"
                                    @click="action.consumer">
                                    <icon-with-text v-if="!!action.icon" :text="action.name" :show-text="action.showText"
                                        :badge-attrs="action.badgeAttrs" icon style="ma-0" :icon-name="action.icon" />
                                    <v-btn v-else small class="ma-1" color="quaternary">
                                        {{ action.name }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
    </div>
</template>

<script>
import http from '../services/http';
import event from '../utils/event';
import urlUtil from '../utils/url';

export default {
    components: {
        'icon-with-text': require('./Icons/IconWithText.vue').default,
        'icon-sex-ratio': require('./Icons/IconSexRatio.vue').default,
    },
    data() {
        return {
            httpState: http.state,
            navBar: false,
            items: [],
            shootings: [],
            albumId: undefined,
        };
    },
    created() {
        event.on('albumSelected', (album) => {
            this.albumId = album.id;
            this.items.push({
                // クリックイベントの識別用 微妙...
                id: 'albumTitle',
                name: album.name,
                children: this.shootings,
            });
        });

        event.on('albumDisselected', () => {
            this.items = [];
        });
    },
    methods: {
        clickNavBarMenu() {
            this.navBar = false;
        },
        treeviewNodeClicked(activeNodes) {
            if (!activeNodes[0]) {
                return;
            } else if (activeNodes[0] == 'albumTitle') {
                // アルバムのタイトル部分がクリックされた場合は撮影行事一覧に飛ばす
                event.emit('orderedToOpenShootings');
            } else {
                event.emit('orderedToOpenShooting', activeNodes[0]);
            }
        },
        fetchShootings() {
            return http.backGroundRequest('get', (url => {
                url = urlUtil.addQueryParamIfDefined(url, 'album_id', this.albumId);

                return url;
            })('shootings'))
                .then((res) => {
                    this.items[0].children = res.data.map((shooting) => {
                        return {
                            id: shooting.id,
                            name: shooting.name,
                        };
                    });
                });
        },
        openOnlineManual() {
            window.open('/アルバム選考システム操作マニュアルver2.pdf', '_blank');
        },
        toggleTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
        logout() {
            http
                .logout()
                .then(() => {
                    this.showMessage = '';
                    location.href = '/login';
                })
                .catch((error) => {
                    if (error == 'unauthorized') {
                        this.showMessage = 'ログアウトに失敗しました。';
                    } else {
                        this.showMessage = error;
                    }
                });
        },
    }
};
</script>

<style scoped>
.v-treeview-node__level {
    width: 12px !important;
}

.no-underline-breadcrumbs>>>a:not(:hover) {
    text-decoration: none !important;
}
</style>